import { getYxList } from './../api'
import editableCell from './../../basicInformation/components/distributorManagement/components/editableCell'
import cancelreasonModal from './../components/cancelReason.vue'
import {
  getofficeList,
  getDLlnumSeach,
  getFXlnumSeach,
  activityApplication,
  getActivityInfo,
  getActivityReal,
  getActivityAttribute
} from "./api";
import axios from "axios";
import { publicPath } from "@/common/constant.js";
import { Number } from 'core-js';
import { getDLMDlnumSeach, getMatklList } from "@/views/agentVerificationActivities/addOredit/api";
import { cisPublicPath2 } from "@/common/constant.js";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
export default {
  components: {
    editableCell,
    cancelreasonModal,
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes,
    },
  },
  name: "",
  data() {
    return {
      breadcrumbData: [{
        path: "/index",
        name: "index",
        title: "首页"
      },
      {
        path: "/agentVerificationActivities",
        name: "agentVerificationActivities",
        title: "代理商市场活动"
      },
      {
        path: "/agentVerificationActivities/writeOff",
        name: "addOredit",
        title: "活动核销"
      }
      ],
      previewImage: '',
      pageLoadFlag: false,
      detilInfo: {},
      columns: [
        {
          title: "种类",
          dataIndex: "type",
          // width: 400,
          scopedSlots: { customRender: 'type' },

        },
        {
          title: "数量",
          dataIndex: "num",
          // width: 400,

        },
        {
          title: "单价(元)",
          dataIndex: "price",
          // width: 400,

        },
        {
          title: "小计(元)",
          dataIndex: "total",
          // width: 400,
          scopedSlots: { customRender: 'total' },
        },
        {
          title: "实际数量",
          dataIndex: "realNum",
          // width: 400,
          scopedSlots: { customRender: 'realNum' },
        },
        {
          title: "实际单价(元)",
          dataIndex: "realPrice",
          // width: 400,
          scopedSlots: { customRender: 'realPrice' },
        },
        {
          title: "实际小计(元)",
          dataIndex: "realTotal",
          // width: 400,
          scopedSlots: { customRender: 'realTotal' },
        },
        {
          title: "备注",
          dataIndex: "remark",
          // width: 400,
          scopedSlots: { customRender: 'remark' },
        },
      ],
      selectedRowKeyscypl:[],
      selectedRowKeys: [],
      MTselectedRowKeys: [],
      lcselectedRowKeys: [],
      zpselectedRowKeys: [],
      TOselectedRowKeys: [],
      otherselectedRowKeys: [],
      tableData: [], // 物料组数据
      MttableData: [],// 媒体宣传数据
      lctableData: [], // 临促数据
      zptableData: [], // 赠品数据
      TOtableData: [], //TO小b数据
      othertableData: [],// 其他
      allNum: Number(0),
      allTotal: Number(0),
      allNum2: Number(0),
      allTotal2: Number(0),
      allNum3: Number(0),
      allTotal3: Number(0),
      allNum4: Number(0),
      allTotal4: Number(0),
      allNum5: Number(0),
      allTotal5: Number(0),
      allNum6: Number(0),
      allTotal6: Number(0),
      allNumCy:Number(0),
      allTotaCy:Number(0),
      fileList: [],
      yxFileList: [],
      jsFileList: [], // 结算凭证上传附件
      fileIds: [],
      yxFileIds: [],
      jsFileIds: [], // 结算凭证上传附件id
      previewVisible: false,
      marketCenterList: [], // 营销中心数据
      marketCenterId: '', //营销中心id
      marketCenter: '',
      officeList: [],// 办事处数据
      officeName: '',// 办事处
      reason:'',// 驳回原因
      officeId: '', // 办事处id
      timeStart: '', // 活动时间
      statusList: [], // 审批状态数据
      statusName: '', //审批状态
      FXDLlnum: '', // 模糊搜索 代理商id
      FXDLlist: [], // 模糊搜索出来的数据
      FXList: [],
      FXName: [],
      FXID: '',
      DLMDName:[],
      DLMDId:'',
      DLMDList:[],
      therm: '',// 主题
      address: '',// 地址
      activityAttributeId: "",// 活动属性
      isAskRes: null, // 是否申请总部资源
      activityAttributeList: [],// 活动属性数据
      targetAmount: '', // 目标金额
      totalInvestment: "", // 投入合计
      advertisement: '',// 待制作广告
      visible: false,
      realTotalMoney: 0, // 实际投入
      actualTotal: 0,
      actualQuantity: 0,
      actualTotal2: 0,
      actualQuantity2: 0,
      actualTotal3: 0,
      actualQuantity3: 0,
      actualTotal4: 0,
      actualQuantity4: 0,
      actualTotal5: 0,
      actualQuantity5: 0,
      actualTotal6: 0,
      actualQuantity6: 0,
      activityActualsales:0,
      delFileId:[],
      columns1: [
        {
          title: "物料组",
          dataIndex: "matklName",
          width: 180,
          scopedSlots: { customRender: 'type' },
        },
        {
          title: "实际达成（全门店，万元）",
          dataIndex: "storeWideSales",
          width: 180,
          scopedSlots: { customRender: 'storeWideSales' },
        },
        {
          title: "实际达成（全渠道，万元）",
          dataIndex: "OmniChannelSales",
          width: 180,
          scopedSlots: { customRender: 'OmniChannelSales' },
        },
        {
          title: "核销金额：元",
          dataIndex: "writeOffAmount",
          width: 180,
          scopedSlots: { customRender: 'num' },
        },
        {
            title: "计划投入分摊金额：元",
            dataIndex: "applyCost",
            width: 180,
            scopedSlots: { customRender: 'applyCost' },
          },
      ],
      tableData1:[
        {
          writeOffAmount:0
        }
      ],
      MarketTableList:[],
      ActivityEndTime:'',// 活动结束时间
      ActivityStartTime:'',// 活动开始时间
    };
  },


  mounted() {
    this.getYYXT()
    this.getActivityAttributeList();//获取活动属性
    this.type = this.$route.query.type
    if (this.type == 'hexiao') {
      this.getSignleInfo(this.$route.query.id)
      // this.getActivityRealId(this.$route.query.id)
    }
  },

  methods: {
    // 修改参与门店
    handleChangeDLList(value, e) {
      this.DLMDId = value;
    },
    selectAllDLMD() {
      let arr = [];
      this.DLMDList.forEach((item) => {
        arr.push(item.code);
      });
      this.DLMDName = arr;
    },
    // 清空 -- 清空select绑定的值
    clearAllDLMD() {
      this.DLMDName = [];
    },
    changewriteOffAmount(e) {

      this.allTotaCy = 0
      if(this.tableData1.length > 0) {
        this.tableData1.forEach(item=>{
          this.allTotaCy = this.accAdd( this.allTotaCy,Number(item.writeOffAmount))
        })
      }
    },
    getMarketList(id) {
      this.MarketTableList = []
      let data  = {
        custId:id
      }
      getMatklList(data).then(res=>{

        this.MarketTableList = res.data
      })
    },
    onSelectChangecypl(selectedRowKeys) {
      this.selectedRowKeyscypl = selectedRowKeys;
    },
    // 格式化数量 只能输入大于等于0的数字
    transformNum(value){
      return value.toString().replace(/[^\d]/g,'')
    },
    transformPrice(value){
      return value.toString().replace(/[^\d.]/g, "")
    },
    transNum(n){
      return n.toString().replace(/[^\d.]/g, "")
    },
    // 数据相乘精度
    accMul(arg1, arg2) {
      var m = 0,
          s1 = arg1.toString().replace(/[^\d.]/g,''),
          s2 = arg2.toString().replace(/[^\d.]/g,'');
      try {
        m += (s1.split(".")[1]?s1.split(".")[1].length:'')
      } catch (e) {
        console.log(e);
      }
      try {
        m += (s2.split(".")[1]?s2.split(".")[1].length:'')
      } catch (e) {
        console.log(e);
      }

      return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
    },
    // 解决计算失去精度的方法
    accAdd(arg1, arg2) {
      const arg1_ = arg1.toString().replace(/[^\d.]/g, "");
      const arg2_ = arg2.toString().replace(/[^\d.]/g, "");
      var r1, r2, m;
      try {
        r1 = arg1_ && arg1_.toString().split(".")[1]?arg1_.toString().split(".")[1].length:''
      } catch (e) {
        r1 = 0
      }
      try {
        r2 = arg2_ && arg2_.toString().split(".")[1]?arg2_.toString().split(".")[1].length:''
      } catch (e) {
        r2 = 0
      }
      m = Math.pow(10, Math.max(r1, r2))
      return (arg1_ * m + arg2_ * m) / m
    },
    goback() {
      window.close()
      this.$router.push({ path: '/agentVerificationActivities' });
    },
    changeDl(value, e) {
      this.dlId = value
    },
    getDL(id) {
      let data = {
        agentId: id
      };
      getDLMDlnumSeach(data).then(res => {
        this.DLMDList = res.data.data;
      });
    },
    // 如果是编辑页面获取单条数据
    getSignleInfo(id) {
      let data = {
        id: id
      }
      getActivityInfo(data).then(res => {
        this.marketCenterId = res.data.data.marketCenterId
        // 传获取到的数据循环判断得出 营销中心code 为了获取办事处数据
        let marketCode = ""
        this.marketCenterList.forEach(item => {
          if (item.id == this.marketCenterId) {
            marketCode = item.code
          }
        })
        this.reason=res.data.data.reason
        // this.getOffice(marketCode)
        this.officeName = res.data.data.orgName
        this.officeId = res.data.data.orgId
        this.timeStart = res.data.data.applyMonth
        this.FXDLlnum = res.data.data.custInfoId_name
        this.dlId = res.data.data.custInfoId
        this.getMarketList(this.dlId)
        this.therm = res.data.data.activityTheme
        this.address = res.data.data.activityPlace
        this.activityAttributeId = res.data.data.activityAttributeId;
        this.isAskRes = res.data.data.isAskRes;
        this.targetAmount = res.data.data.activityTarget
        // 活动开始时间
        this.ActivityStartTime = res.data.data.applyStartTime;
        // 活动结束时间
        this.ActivityEndTime = res.data.data.applyEndTime;
        this.getFx(this.dlId)
        this.getDL(this.dlId)
        if (res.data.data.fxCust&& res.data.data.fxCust.length>0&& res.data.data.fxCust[0]) {
          this.FXName = res.data.data.fxCust[0].split(',')
        } else {
          this.FXName = []
        }
        if (res.data.data.cyShop && res.data.data.cyShop.length>0&& res.data.data.cyShop[0]) {
          this.DLMDName = res.data.data.cyShop[0].split(",");
        } else {
          this.DLMDName = [];
        }
        this.FXId = res.data.data.fxCust&& res.data.data.fxCust.length>0? res.data.data.fxCust[0].split(","):'';
        this.DLMDId = res.data.data.cyShop&&res.data.data.cyShop.length>0 ?res.data.data.cyShop[0].split(","):'';


        this.totalInvestment = res.data.data.totalMoney
        this.advertisement = res.data.data.adCompany
        this.tableData = res.data.data.matklDtoList
        this.MttableData = res.data.data.mediaDtoList
        this.zptableData = res.data.data.giftDtoList
        this.lctableData = res.data.data.tempDtoList
        this.TOtableData = res.data.data.tobDtoList
        this.othertableData = res.data.data.otherDtoList
        // 参与品类
        this.tableData1 = res.data.data.productLineDtoList;
        // 电视强制改成平板电视显示
        this.tableData1.find(j=>j.matklName == '电视').matklName = '平板电视';

        if(this.tableData1.length > 0) {
          this.tableData1.forEach(item=>{
            this.allTotaCy = this.accAdd( this.allTotaCy,Number(item.writeOffAmount))
            // // 参数全有才能调 否则后台报错
            if(this.$route.query.id && this.ActivityStartTime && this.ActivityEndTime && item.matklId){
              this.$nextTick(()=>{
                // 获取销售额接口
                let data = {
                  id: this.$route.query.id,
                  applyStartTime:this.ActivityStartTime,// 活动开始时间
                  applyEndTime:this.ActivityEndTime,//活动结束时间
                  matklId:item.matklId
                }
                getActivityReal(data).then(res=>{
                  if(res.data.code == 0) {
                    this.$set(item, 'storeWideSales' , res.data.data.activityShopReal);
                    this.$set(item, 'OmniChannelSales' , res.data.data.activityChannelReal);
                  }
                })
              })
              
            }
          })
          this.allNumCy = this.tableData1.length
        }
        if (this.TOtableData.length > 0) {
          this.TOtableData.forEach(item => {
            item.realPrice = 0
            item.realNum = 0
            item.total = this.accMul(item.num,item.price)
            this.allNum5 = this.accAdd( this.allNum5,Number(item.num))
            this.allTotal5 = this.accAdd( this.allTotal5,Number(item.total))
          });
        }
        if (this.othertableData.length > 0) {
          this.othertableData.forEach(item => {
            item.realPrice = 0
            item.realNum = 0
            item.total = this.accMul(item.num,item.price)
            this.allNum6 = this.accAdd( this.allNum6,Number(item.num))
            this.allTotal6 = this.accAdd( this.allTotal6,Number(item.total))
          });
        }
        if (this.tableData.length > 0) {
          this.tableData.forEach(item => {
            item.realPrice = 0
            item.realNum = 0
            item.total = this.accMul(item.num,item.price)
            this.allNum = this.accAdd( this.allNum,Number(item.num))
            this.allTotal = this.accAdd( this.allTotal,Number(item.total))
          })
        }

        if (this.MttableData.length > 0) {
          this.MttableData.forEach(item => {
            item.realPrice = 0
            item.realNum = 0
            item.total = this.accMul(item.num,item.price)
            this.allNum2 = this.accAdd( this.allNum2,Number(item.num))
            this.allTotal2 = this.accAdd( this.allTotal2,Number(item.total))
          });
        }
        if (this.lctableData.length > 0) {
          this.lctableData.forEach(item => {
            item.realPrice = 0
            item.realNum = 0
            item.total = this.accMul(item.num,item.price)
            this.allNum3 = this.accAdd( this.allNum3,Number(item.num))
            this.allTotal3 = this.accAdd( this.allTotal3,Number(item.total))
          });
        }
        if (this.zptableData.length > 0) {
          this.zptableData.forEach(item => {
            item.realPrice = 0
            item.realNum = 0
            item.total = this.accMul(item.num,item.price)
            this.allNum4 = this.accAdd( this.allNum4,Number(item.num))
            this.allTotal4 = this.accAdd( this.allTotal4,Number(item.total))
          });
        }

        let fff = []
        if (res.data.data.attachs) {
          res.data.data.attachs.forEach(item => {
            fff.push({
              id: (item.id).toString()
            })
          })
        }
        this.fileIds = fff
        if (res.data.data.attachs) {
          res.data.data.attachs.forEach(item => {

            this.fileList.push(
              {
                name:item.attachName,
                uid: item.id,
                name2: "旧的",
                status: "done",
                url: item.attachPath,
              },
            );
          })
        }
        let fff2 = []
        if (res.data.data.sumUpAttachs) {
          res.data.data.sumUpAttachs.forEach(item => {
            fff2.push({
              id: (item.id).toString()
            })
          })
        }
        this.yxFileIds = fff2
        if (res.data.data.sumUpAttachs) {
          res.data.data.sumUpAttachs.forEach(item => {

            this.yxFileList.push(
              {
                name:item.attachName,
                uid: item.id,
                name2: "旧的",
                status: "done",
                url: item.attachPath,
              },
            );
          })
        }

        // 结算凭证附件回显
        let fff3 = []
        if (res.data.data.voucherAttachs) {
          res.data.data.voucherAttachs.forEach(item => {
            fff3.push({
              id: (item.id).toString()
            })
          })
        }
        this.jsFileIds = fff3
        if (res.data.data.voucherAttachs) {
          res.data.data.voucherAttachs.forEach(item => {

            this.jsFileList.push(
              {
                name:item.attachName,
                uid: item.id,
                name2: "旧的",
                status: "done",
                url: item.attachPath,
              },
            );
          })
        }
      })
    },
    getReason($event, value) {
      this.bscManager = value
      this.content = $event
      this.visible = false
      this.pageLoadFlag = true
      this.confim()

    },
    handleChangeFXList(value, e) {
      this.FXId = value
    },
    // 输入模糊搜索代理商
    getDLList(value) {
      this.FXDLlnum = value
      let data = {
        searchStr: this.FXDLlnum
      }
      getDLlnumSeach(data).then(res => {
        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data)

        } else {
          data = res.data;
        }
        this.FXDLlist = [...new Set(data.data)]; //缓存列表数据，前端分页
      })
    },
    //查询数据
    search() {
      this.getFx(this.FXDLlnum)
    },
    // 获取分销商
    getFx(id) {
      let data = {
        agentId: id
      }
      getFXlnumSeach(data).then(res => {
        this.FXList = res.data.data
      })
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    // 时间更改
    handleTimeChange(e, defaultTime) {
      this.timeStart = defaultTime;
    },

    //获取活动属性数据
    getActivityAttributeList() {
      getActivityAttribute().then(res => {
        if(res.data && res.data.length > 0){
          this.activityAttributeList = res.data.map(j=>{
            return{
              ...j,
              code: Number(j.code)
            }
          })
        }
      });
    },
    //获取营销zhongxin数据
    getYYXT() {
      let data = {}
      getYxList(data).then(res => {
        this.marketCenterList = res.data.data
      })
    },
    // 更改营销
    handleChangeshopList(value, e) {
      this.marketCenterId = value;
      this.marketCenter = e.key;
      this.officeList = []
      this.officeName = ''
      this.officeId = ''
      this.getOffice(this.marketCenter)
    },
    // 根据营销中心获取办事处数据
    getOffice(id) {
      let data = {
        orgCode: id
      }
      getofficeList(data).then(res => {
        this.officeList = res.data.data
        this.officeName = res.data.data[0].name
        this.officeId = res.data.data[0].id
      })
    },
    handleChangeofficeList(value, e) {
      this.officeName = value
      this.officeId = e.key
    },

    // 新增活动代码或者编辑活动代码或者核销活动代码
    // 复制行数据
    copy(num) {
      if (num == '1') {
        if (this.selectedRowKeys.length > 1) {
          this.$.warning('只能一条数据进行复制!')
        } else {
          let aa = {}
          this.tableData.forEach((item, index) => {
            this.selectedRowKeys.forEach(i => {
              if (index == i) {
                aa = this.tableData[index]
              }
            })
          })
          this.tableData.push(aa)
        }
      } else if (num == '2') {
        if (this.MTselectedRowKeys.length > 1) {
          this.$message.warning('只能一条数据进行复制!')
        } else {
          let aa = {}
          this.MttableData.forEach((item, index) => {
            this.MTselectedRowKeys.forEach(i => {
              if (index == i) {
                aa = this.MttableData[index]
              }
            })
          })
          this.MttableData.push(aa)
        }
      } else if (num == '3') {
        if (this.lcselectedRowKeys.length > 1) {
          this.$message.warning('只能一条数据进行复制!')
        } else {
          let aa = {}
          this.lctableData.forEach((item, index) => {
            this.lcselectedRowKeys.forEach(i => {
              if (index == i) {
                aa = this.lctableData[index]
              }
            })
          })
          this.lctableData.push(aa)
        }

      } else if (num == '4') {
        if (this.zpselectedRowKeys.length > 1) {
          this.$message.warning('只能一条数据进行复制!')
        } else {
          let aa = {}
          this.zptableData.forEach((item, index) => {
            this.zpselectedRowKeys.forEach(i => {
              if (index == i) {
                aa = this.zptableData[index]
              }
            })
          })
          this.zptableData.push(aa)
        }
      }
    },

    //删除行数据
    delLine(num) {
      if (num == 'one') {
        if (this.selectedRowKeys.length <= 0) {
          this.$message.warning('请至少选择一项')
        } else {
          if (this.selectedRowKeys.includes(this.tableData.findIndex(j => !j.isAdd))) {
            this.$message.warning('不可删除原始数据')
          } else {
            this.tableData = this.tableData.filter((item, index) => !this.selectedRowKeys.includes(index))
            this.selectedRowKeys = []
          }

        }
      }
      if (num == 'two') {
        if (this.MTselectedRowKeys.length <= 0) {
          this.$message.warning('请至少选择一项')
        } else {
          this.MttableData = this.MttableData.filter((item, index) => !this.MTselectedRowKeys.includes(index))
          this.MTselectedRowKeys = []
        }
      }
      if (num == 'three') {
        if (this.lcselectedRowKeys.length <= 0) {
          this.$message.warning('请至少选择一项')
        } else {
          this.lctableData = this.lctableData.filter((item, index) => !this.lcselectedRowKeys.includes(index))
          this.lcselectedRowKeys = []
        }
      }

      if (num == 'four') {
        if (this.zpselectedRowKeys.length <= 0) {
          this.$message.warning('请至少选择一项')
        } else {
          this.zptableData = this.zptableData.filter((item, index) => !this.zpselectedRowKeys.includes(index))
          this.zpselectedRowKeys = []
        }
      }

    },
    // 勾选数据
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    MTonSelectChange(selectedRowKeys) {
      this.MTselectedRowKeys = selectedRowKeys;
    },
    LConSelectChange(selectedRowKeys) {
      this.LCselectedRowKeys = selectedRowKeys;
    },
    ZPonSelectChange(selectedRowKeys) {
      this.zpselectedRowKeys = selectedRowKeys;
    },
    TOonSelectChange(selectedRowKeys) {
      this.TOselectedRowKeys = selectedRowKeys;
    },
    otheronSelectChange(selectedRowKeys) {
      this.otherselectedRowKeys = selectedRowKeys
    },

    // 添加行
    addline(num) {
      if (num == 'one') {
        this.tableData.push(
          {
            isAdd: true,
            type: '',
            num: 0,
            price: 0,
            total: 0,
            remark: '',
            realNum: 0,
            realPrice: 0,
            realTotal: 0
          }
        )
      }
      if (num == 'two') {
        this.MttableData.push(
          {
            type: '',
            num: 0,
            price: 0,
            total: 0,
            remark: '',
            realNum: 0,
            realPrice: 0,
            realTotal: 0
          }
        )
      }
      if (num == 'three') {
        this.lctableData.push(
          {
            type: '',
            num: 0,
            price: 0,
            total: 0,
            remark: '',
            realNum: 0,
            realPrice: 0,
            realTotal: 0
          }
        )
      }
      if (num == 'four') {
        this.zptableData.push(
          {
            type: '',
            num: 0,
            price: 0,
            total: 0,
            remark: '',
            realNum: 0,
            realPrice: 0,
            realTotal: 0
          }
        )
      }
    },
    // 更改数量
    changeNum(num) {
      if (num == 'one') {
        this.actualQuantity = 0
        this.actualTotal = 0
        this.tableData.forEach(item => {
          item.realTotal = this.accMul(this.transNum(item.realNum),this.transNum(item.realPrice))
          this.actualQuantity = this.accAdd( this.actualQuantity,Number(this.transNum(item.realNum)))
          this.actualTotal = this.accAdd( this.actualTotal,Number(this.transNum(item.realTotal)))
        });
      }
      if (num == 'two') {
        this.actualQuantity2 = 0
        this.actualTotal2 = 0
        this.MttableData.forEach(item => {
          item.realTotal = this.accMul(item.realNum,item.realPrice)
          this.actualQuantity2 = this.accAdd( this.actualQuantity2,Number(item.realNum))
          this.actualTotal2 = this.accAdd( this.actualTotal2,Number(item.realTotal))
        });
      }
      if (num == 'three') {
        this.actualQuantity3 = 0
        this.actualTotal3 = 0
        this.lctableData.forEach(item => {
          item.realTotal = this.accMul(item.realNum,item.realPrice)
          this.actualQuantity3 = this.accAdd( this.actualQuantity3,Number(item.realNum))
          this.actualTotal3 = this.accAdd( this.actualTotal3,Number(item.realTotal))
        });
      }
      if (num == 'four') {
        this.actualQuantity4 = 0
        this.actualTotal4 = 0
        this.zptableData.forEach(item => {
          item.realTotal = this.accMul(item.realNum,item.realPrice)
          this.actualQuantity4 = this.accAdd( this.actualQuantity4,Number(item.realNum))
          this.actualTotal4 = this.accAdd( this.actualTotal4,Number(item.realTotal))
        });
      }
      if (num == 'five') {
        this.actualQuantity5 = 0
        this.actualTotal5 = 0
        this.TOtableData.forEach(item => {
          item.realTotal = this.accMul(item.realNum,item.realPrice)
          this.actualQuantity5 = this.accAdd( this.actualQuantity5,Number(item.realNum))
          this.actualTotal5 = this.accAdd( this.actualTotal5,Number(item.realTotal))
        });
      }
      if (num == 'six') {
        this.actualQuantity6 = 0
        this.actualTotal6 = 0
        this.othertableData.forEach(item => {
          item.realTotal = this.accMul(item.realNum,item.realPrice)
          this.actualQuantity6 = this.accAdd( this.actualQuantity6,Number(item.realNum))
          this.actualTotal6 = this.accAdd( this.actualTotal6,Number(item.realTotal))

        });
      }
    },
    // 更改价格
    changePrice(num) {
      if (num == 'one') {
        this.actualQuantity = 0
        this.actualTotal = 0
        this.tableData.forEach(item => {
          item.realTotal = this.accMul(this.transNum(item.realNum),this.transNum(item.realPrice))
          this.actualQuantity = this.accAdd( this.actualQuantity,Number(this.transNum(item.realNum)))
          this.actualTotal = this.accAdd( this.actualTotal,Number(this.transNum(item.realTotal)))
        });
      }
      if (num == 'two') {
        this.actualQuantity2 = 0
        this.actualTotal2 = 0
        this.MttableData.forEach(item => {
          item.realTotal = this.accMul(item.realNum,item.realPrice)
          this.actualQuantity2 = this.accAdd( this.actualQuantity2,Number(item.realNum))
          this.actualTotal2 = this.accAdd( this.actualTotal2,Number(item.realTotal))
        });
      }
      if (num == 'three') {
        this.actualQuantity3 = 0
        this.actualTotal3 = 0
        this.lctableData.forEach(item => {
          item.realTotal = this.accMul(item.realNum,item.realPrice)
          this.actualQuantity3 = this.accAdd( this.actualQuantity3,Number(item.realNum))
          this.actualTotal3 = this.accAdd( this.actualTotal3,Number(item.realTotal))
        });
      }
      if (num == 'four') {
        this.actualQuantity4 = 0
        this.actualTotal4 = 0
        this.zptableData.forEach(item => {
          item.realTotal = this.accMul(item.realNum,item.realPrice)
          this.actualQuantity4 = this.accAdd( this.actualQuantity4,Number(item.realNum))
          this.actualTotal4 = this.accAdd( this.actualTotal4,Number(item.realTotal))
        });
      }
      if (num == 'five') {
        this.actualQuantity5 = 0
        this.actualTotal5 = 0
        this.TOtableData.forEach(item => {
          item.realTotal = this.accMul(item.realNum,item.realPrice)
          this.actualQuantity5 = this.accAdd( this.actualQuantity5,Number(item.realNum))
          this.actualTotal5 = this.accAdd( this.actualTotal5,Number(item.realTotal))
        });
      }
      if (num == 'six') {
        this.actualQuantity6 = 0
        this.actualTotal6 = 0
        this.othertableData.forEach(item => {
          item.realTotal = this.accMul(item.realNum,item.realPrice)
          this.actualQuantity6 = this.accAdd( this.actualQuantity6,Number(item.realNum))
          this.actualTotal6 = this.accAdd( this.actualTotal6,Number(item.realTotal))
        });
      }
    },
    // 结束
    // 上传附件
    beforeUpload(file) {
      // 不可大于30M
      const size = (file.size / 1024 / 1024) < 30;
      if (!size) {
        this.$message.warning('单个文件上传不能超过30M！');
        return
      }
      this.pageLoadFlag = true
      // this.fileList = [...this.fileList, file];
      const formData = new FormData();
      formData.append("file", file);
      // 上传接口/custAgentActivity/uploadFile.nd
      let config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      // 发送请求
      const BASE_API = publicPath;
      axios.post(BASE_API + '/custAgentActivity/uploadFile.nd', formData, config).then(res => {
        this.pageLoadFlag = false
        if (res.data.message == '上传成功') {
          this.fileList = [...this.fileList, file];
          this.fileIds.push({
            id: res.data.businessId
          })
        } else {
          this.$message.warning('上传失败', res.data.message)
        }
      }).catch(error=>{
        this.$message.warning('上传失败'+error)
        this.pageLoadFlag = false
      })
      return false;
    },
    handleChange({ fileList }) {
      let fileList1 = [...this.fileList];
      this.fileList = fileList1;
    },
    handleRemove(file,item) {
      const index = file;
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
      this.fileIds.splice(index, 1);
      if(item.name2 == '旧的') {
        this.delFileId.push({
          id: item.uid
        });
      }
    },

    // 上传营销活动附件
    beforeUpload2(file) {
      // 不可大于30M
      const size = (file.size / 1024 / 1024) < 30;
      if (!size) {
        this.$message.warning('单个文件上传不能超过30M！');
        return
      }
      this.pageLoadFlag = true
      // this.fileList = [...this.fileList, file];
      const formData = new FormData();
      formData.append("file", file);
      // 上传接口/custAgentActivity/uploadFile.nd
      let config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      // 发送请求
      const BASE_API = publicPath;
      axios.post(BASE_API + '/custAgentActivity/uploadFile.nd', formData, config).then(res => {
        this.pageLoadFlag = false
        if (res.data.message == '上传成功') {
          this.yxFileList = [...this.yxFileList, file];
          this.yxFileIds.push({
            id: res.data.businessId
          })
        } else {
          this.$message.warning('上传失败', res.data.message)
        }
      }).catch(error=>{
        this.$message.warning('上传失败'+error)
        this.pageLoadFlag = false
      })
      return false;
    },

    // 上传营销活动附件
    beforeUpload3(file) {
      // 不可大于30M
      const size = (file.size / 1024 / 1024) < 30;
      if (!size) {
        this.$message.warning('单个文件上传不能超过30M！');
        return
      }
      this.pageLoadFlag = true
      // this.fileList = [...this.fileList, file];
      const formData = new FormData();
      formData.append("file", file);
      // 上传接口/custAgentActivity/uploadFile.nd
      let config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      // 发送请求
      const BASE_API = publicPath;
      axios.post(BASE_API + '/custAgentActivity/uploadFile.nd', formData, config).then(res => {
        this.pageLoadFlag = false
        if (res.data.message == '上传成功') {
          this.jsFileList = [...this.jsFileList, file];
          this.jsFileIds.push({
            id: res.data.businessId
          })
        } else {
          this.$message.warning('上传失败', res.data.message)
        }
      }).catch(error=>{
        this.$message.warning('上传失败'+error)
        this.pageLoadFlag = false
      })
      return false;
    },
    handleChange2({ fileList }) {
      let fileList1 = [...this.yxFileList];
      this.yxFileList = fileList1;
    },
    handleRemove2(file,item) {
      const index = file;
      const newFileList = this.yxFileList.slice();
      newFileList.splice(index, 1);
      this.yxFileList = newFileList;
      this.yxFileIds.splice(index, 1);
      if(item.name2 == '旧的') {
        this.delFileId.push({
          id: item.uid
        });
      }
    },
    handleChange3({ fileList }) {
      let fileList1 = [...this.jsFileList];
      this.jsFileList = fileList1;
    },
    handleRemove3(file,item) {
      const index = file;
      const newFileList = this.jsFileList.slice();
      newFileList.splice(index, 1);
      this.jsFileList = newFileList;
      this.jsFileIds.splice(index, 1);
      if(item.name2 == '旧的') {
        this.delFileId.push({
          id: item.uid
        });
      }
    },
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {

      if (file.url) {
        window.open(file.url)
      } else {
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj);
        }
        this.previewImage = file.url || file.preview;
        this.previewVisible = true;
      }
    },
    handleDownload(file) {
      if (file.url) {
        if(file.url.indexOf('tomcat')!= -1 ){ 
          window.open((cisPublicPath2 +'file-rest/showFile?actionMethod=showFile&pathInfo=' + file.url + '&fileName=' + file.name), '_blank')
        } else{ 
          window.open(file.url, '_blank')
        }
      }
    },
    // 上传附件代码结束

    openWindow() {
      if (this.tableData.some(j => !j.type)) {
        return this.$message.info('请正确填写物料的种类')
      }
      this.visible = true
    },

    // 提交
    confim() {
      // this.pageLoadFlag = true
      let id = this.type == 'add' ? '0' : this.$route.query.id
      let dlsId = this.type == 'add' ? this.FXDLlnum : this.dlId
      let fxIds = this.type == 'add' ? this.FXName : this.FXId
      let DLMDIds = this.type == "add" ? this.DLMDName : this.DLMDId;

      let data = {
        id: id,
        marketCenterId: this.marketCenterId,//营销中心ID
        orgId: this.officeId,//办事处ID
        applyMonth: this.timeStart,//活动申请时间
        custInfoId: dlsId,//代理商ID
        activityTheme: this.therm,//活动主题
        activityPlace: this.address,//活动地点
        activityTarget: this.targetAmount,//活动目标
        activityAttributeId: this.activityAttributeId,//活动属性
        isAskRes: this.isAskRes, // 是否申请总部资源
        fxCust: fxIds,//分销商多选，List类型，传分销商ID
        cyShop: DLMDIds,//代理门店，List类型，传代理商ID
        totalMoney: this.totalInvestment,//各项投入合计
        adCompany: this.advertisement,//待制作广告公司 
        bscManager: this.bscManager,//办事处经理ID
        content: this.content,//申请理由
        matklDtoList: this.tableData,//物料组数据
        mediaDtoList: this.MttableData, // 媒体数据
        tempDtoList: this.lctableData, // 临促数据
        giftDtoList: this.zptableData, // 赠品数据
        //  新添加两个类型
        tobDtoList: this.TOtableData,
        otherDtoList: this.othertableData,
        attachs: this.fileIds, // 附件id
        sumUpAttachs: this.yxFileIds, // 营销附件id
        voucherAttachs: this.jsFileIds, // 结算凭证附件id
        deleteAttachs: this.delFileId,
        realTotalMoney: this.realTotalMoney,
        // 实际销售额
        activityReal: this.activityActualsales,
        productLineDtoList: this.tableData1,
        applyStartTime: this.ActivityStartTime,// 活动开始时间
        applyEndTime: this.ActivityEndTime,//活动结束时间
        // activityShopReal:this.storeWideSales,// 全门店销售额
        // activityChannelReal:this.OmniChannelSales // 全渠道销售额

      }
      activityApplication(data).then(res => {
        if (res.data.code == 0) {
          this.$message.success(res.data.msg)
          this.visible = false
          this.pageLoadFlag = false
          // 关闭窗口之前刷新父页面
          window.opener.location.reload();
          this.timer = setTimeout(() => {  //设置延迟执行
            this.goback()
          }, 2000);
        } else {
          this.pageLoadFlag = false
          this.$message.warning('提交失败,' + res.data.msg)
        }
      })
    }


  }

};